import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Home from './Home';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import CheckIn from './CheckIn';
import CheckOut from './CheckOut';
import Report from './Report';
import PrintOut from './PrintOut';
import CheckList from './CheckList';
import EventSelect from './EventSelect';
import ControlPanel from './ControlPanel';
import Angpao from './Angpao';


class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
        <Route path="/check_in/:event_code">
            <CheckIn />
          </Route>
          <Route path="/check_out/:event_code">
            <CheckOut />
          </Route>
          <Route path="/report/:event_code/:base_id">
            <Report />
          </Route>
          <Route path="/angpao/:event_code/:base_id">
            <Angpao />
          </Route>
          <Route path="/printout/:event_code/:base_id">
            <PrintOut />
          </Route>
          <Route path="/checklist/:event_code/:base_id">
            <CheckList />
          </Route>
          <Route path="/cpanel/:event_code/:base_id">
            <ControlPanel />
          </Route>
          <Route path="/:event_code/:guest_id">
            <Home />
          </Route>
          <Route path="/naisho">
            <EventSelect />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
