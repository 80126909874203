import React, { useState , useEffect } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import './Home.css';
import './CheckList.css';
import {
  useParams
} from "react-router-dom";
import { getEventDetail, getAllGuest } from './AirtableLib';
import LoadingScreen from './LoadingScreen';
import ErrorScreen from './ErrorScreen';

export default function CheckList (props) {
  // kalau butuh state
  const [drawState , setDrawState] = useState(0);
  const [event , setEvent] = useState({});
  const [data , setData] = useState([]);
  const [sortKey , setSortKey] = useState('name');
  const [ascDesc , setAscDesc] = useState(1);

  // Variable yang bukan state
  // const lastId = useRef("")

  let { event_code, base_id } = useParams();

  function fix(input , key) {
    if (input === undefined) {
      return -999
    }

    if (key === 'name') {
      // Buang mr nya
      return input.toLowerCase().replace(/(mrs|mr|ms)\.? */g , "").replace( /[^a-z]/g, '' );
    }
    return input
  }

  function sortField(key) {
    if (key !== sortKey) {
      setSortKey(key)
      setAscDesc(1)
    }
    else {
      setAscDesc(ascDesc * -1)
    }
    // console.log(key, sortKey , ascDesc)
    
    var obj = [...data]

    obj.sort((a , b) => {
      let tempa = fix(a[key] , key)
      let tempb = fix(b[key] , key)

      // console.log(tempa , tempb)

      if (tempa < tempb) {return -1 * ascDesc}
      else if (tempa > tempb) {return 1 * ascDesc}
      return 0
    })
    setData(obj)
  }

  // Pengganti di componentDidMount untuk functional component
  useEffect(() => {
    getEventDetail(event_code, (record) => {
      if (record.fields.base !== base_id) {
        console.log(record)
        console.log(record.fields.base , base_id)
        // Salah password
        setDrawState(4)
        return
      }
      setEvent(record.fields)
      // Success
      getAllGuest(event_code , (record2) => {
        var _data = []
        record2.forEach(guest => {
          _data.push({
            id: guest.id , 
            no: guest.fields.no,
            name: guest.fields.Name,
            phone: guest.fields.Phone,
            email: guest.fields.Email,
            is_rsvp: guest.fields.is_rsvp?"✅":"❌",
            is_coming: guest.fields.is_coming?"✅":"❌",
            is_came: guest.fields.is_came?"✅":"❌",
            souvernir_pickup: guest.fields.souvernir_pickup?"✅":"❌",
            PersonComing: guest.fields.PersonComing,
            seating_number: guest.fields['Seating Number'],
            table_number: guest.fields['Table Number'],
          })
        });
        setData(_data)
        setDrawState(2)
      } , (err) => {
        // Gagal
        setDrawState(1)
      })
    }, () => {
      // Gagal
      setDrawState(1)
    })
  }, []) // Set empty dependency biar ga load hanya sekali, biar setRecord ga trigger ulang2

  if (drawState === 0) {
    return (
      <LoadingScreen />
    )
  }
  else if (drawState === 1) {
    return (
      <ErrorScreen message="We cannot find your invitation" />
    )
  }
  else if (drawState === 4) {
    return (
      <ErrorScreen message="Wrong Password" />
    )
  }
  else if (drawState === 2) {
    return (
      <Container>
        <Row>
          <Col>
            <h2>{event.Name}</h2>
          </Col>
        </Row>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={(e) => sortField('no' , e)}>No</th>
            <th onClick={(e) => sortField('name' , e)}>Name</th>
            <th onClick={(e) => sortField('is_rsvp' , e)}>RSVP</th>
            <th onClick={(e) => sortField('is_coming' , e)}>Coming</th>
            <th onClick={(e) => sortField('PersonComing' , e)}>PersonComing</th>
            <th onClick={(e) => sortField('table_number' , e)}>Table</th>
            <th onClick={(e) => sortField('seating_number' , e)}>Seat</th>
          </tr>
        </thead>
        <tbody>
          {data.map((value, index) => {
            if (value.is_coming) {
              return (
                <tr key={value.id}>
                  <td>{value.no}</td>
                  <td>{value.name}</td>
                  <td>{value.is_rsvp}</td>
                  <td>{value.is_coming}</td>
                  <td>{value.PersonComing}</td>
                  <td>{value.table_number}</td>
                  <td>{value.seating_number}</td>
                </tr>
              )
            }
            else return null
          })}
        </tbody>

        </Table>
      </Container>
    );
  }
}
