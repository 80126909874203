import React, { useState, useEffect } from 'react';
import { Alert, Button, Col, Container, Form, FormText, Row } from 'react-bootstrap';
import './Home.css';
import {
    useParams
} from "react-router-dom";
import { getEventDetail, getAllGuest, updateGuest, createGuest } from './AirtableLib';
import LoadingScreen from './LoadingScreen';
import ErrorScreen from './ErrorScreen';
import MyPie from './MyPie';
import BootstrapTable from 'react-bootstrap-table-next';

export default function ControlPanel(props) {
    // kalau butuh state
    const [drawState, setDrawState] = useState(0);
    const [event, setEvent] = useState({});
    const [totalInvitation, setTotalInvitation] = useState(0);
    const [totalRsvp, setTotalRsvp] = useState(0);
    const [totalNotRsvp, setTotalNotRsvp] = useState(0);
    const [totalComing, setTotalComing] = useState(0);
    const [totalNotComing, setTotalNotComing] = useState(0);
    const [totalCame, setTotalCame] = useState(0);
    const [totalNotCame, setTotalNotCame] = useState(0);
    const [totalSouvernir, setTotalSouvernir] = useState(0);
    const [totalNotSouvernir, setTotalNotSouvernir] = useState(0);
    const [totalPersonComing, setTotalPersonComing] = useState(0);
    const [totalPersonCame, setTotalPersonCame] = useState(0);
    const [totalPersonSouvernir, setTotalPersonSouvernir] = useState(0);
    const [graphData, setGraphData] = useState([]);
    const [data, setData] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [filterPhone, setFilterPhone] = useState('');
    const [filterTable, setFilterTable] = useState('');
    const [filterSeating, setFilterSeating] = useState('');
    const [filterNumber, setFilterNumber] = useState('');
    const [detailRow, setDetailRow] = useState({});
    const [maxNumber, setMaxNumber] = useState(0);

    // Variable yang bukan state
    // const lastId = useRef("")

    let { event_code, base_id } = useParams();

    function reloadData() {
        setDrawState(0) // Balik ke loading screen
        getEventDetail(event_code, (record) => {
            if (record.fields.base !== base_id) {
                // Salah password
                setDrawState(4)
                return
            }
            setEvent(record.fields)
            // Success
            getAllGuest(event_code, (record2) => {
                var total_invitation = 0
                var is_rsvp_total = 0
                var is_coming_total = 0
                var is_came_total = 0
                var souvernir_pickup_total = 0
                var total_person_coming = 0
                var total_person_came = 0
                var total_person_souvernir = 0
                var total_person = 0
                var _data = []
                var max_n = 0
                record2.forEach(guest => {
                    total_invitation += 1
                    if (guest.fields.is_rsvp) is_rsvp_total += 1
                    if (guest.fields.is_coming) {
                        is_coming_total += 1
                        total_person_coming += guest.fields.PersonComing || 0
                    }
                    if (guest.fields.is_came) {
                        is_came_total += 1
                        total_person_came += guest.fields.PersonComing || 0
                    }
                    if (guest.fields.souvernir_pickup) {
                        souvernir_pickup_total += 1
                        total_person_souvernir += guest.fields.PersonComing || 0
                    }
                    if (parseInt(guest.fields.no) > max_n) {
                        max_n = parseInt(guest.fields.no)
                    }
                    _data.push({
                        id: guest.id,
                        name: guest.fields.Name,
                        table_number: guest.fields['Table Number'],
                        seat_number: guest.fields['Seating Number'],
                        answers: guest.fields['Answers'],
                        notes: guest.fields['notes'],
                        phone: guest.fields['Phone'],
                        number: guest.fields['no'],
                        is_rsvp: guest.fields.is_rsvp ? "✅" : "❌",
                        is_rsvp_bool: guest.fields.is_rsvp || false,
                        is_coming: guest.fields.is_coming ? "✅" : "❌",
                        is_coming_bool: guest.fields.is_coming || false,
                        is_came: guest.fields.is_came ? "✅" : "❌",
                        is_came_bool: guest.fields.is_came || false,
                        souvernir_pickup: guest.fields.souvernir_pickup ? "✅" : "❌",
                        souvernir_pickup_bool: guest.fields.souvernir_pickup,
                        person_coming: guest.fields.PersonComing,
                        checkin_device_id: guest.fields.checkin_device_id,
                        is_angpao: guest.fields.is_angpao,
                        qr: guest.fields.QR,
                    })
                });
                setData(_data)
                setTotalInvitation(total_invitation)
                setTotalRsvp(is_rsvp_total)
                setTotalNotRsvp(total_invitation - is_rsvp_total)
                setTotalComing(is_coming_total)
                setTotalNotComing(is_rsvp_total - is_coming_total)
                setTotalCame(is_came_total)
                setTotalNotCame(is_coming_total - is_came_total)
                setTotalSouvernir(souvernir_pickup_total)
                setTotalNotSouvernir(is_came_total - souvernir_pickup_total)
                setTotalPersonComing(total_person_coming)
                setTotalPersonCame(total_person_came)
                setTotalPersonSouvernir(total_person_souvernir)
                setMaxNumber(max_n)

                setGraphData([
                    { name: 'Coming', value: is_coming_total, fill: '#799351' },
                    { name: 'Not Yet RSVP', value: total_invitation - is_rsvp_total, fill: '#ebdc87' },
                    { name: 'Not Coming', value: is_rsvp_total - is_coming_total, fill: '#d54062' },
                ])

                setDrawState(2)

            }, (err) => {
                // Gagal
                setDrawState(1)
            })
        }, () => {
            // Gagal
            setDrawState(1)
        })
    }

    // Pengganti di componentDidMount untuk functional component
    useEffect(() => {
        reloadData()
    }, []) // Set empty dependency biar ga load hanya sekali, biar setRecord ga trigger ulang2\

    const viewDetail = (o) => {
        setDetailRow(o)
        setDrawState(5)
    }

    function addNewData() {
        setDetailRow({
            'number': maxNumber + 1
        })
        setDrawState(7)
    }

    const cancelEdit = (o) => {
        setDrawState(2)
    }

    function saveCheckIn(id) {
        setDrawState(6)
        updateGuest([
            {
                "id": id,
                "fields": {
                    "is_came": true,
                    "came_time": Date.now(),
                    // Simpan juga data lain nya
                    "Name": detailRow.name,
                    "Phone": detailRow.phone,
                    "PersonComing": parseInt(detailRow.person_coming),
                    "Table Number": detailRow.table_number,
                    "Seating Number": detailRow.seat_number,
                    "Answers": detailRow.answers,
                    "notes": detailRow.notes,
                    "no": parseInt(detailRow.number),
                    "is_rsvp": detailRow.is_rsvp_bool,
                    "is_coming": detailRow.is_coming_bool,
                    "checkin_device_id": detailRow.checkin_device_id,
                    "is_angpao": detailRow.is_angpao,
                }
            }
        ], () => {
            // Success
            reloadData()
        }, () => {
            // Error
            setDrawState(5)
            alert("Cannot submit data. Please try again later.")
        })
    }

    function saveCheckOut(id) {
        setDrawState(6)
        updateGuest([
            {
                "id": id,
                "fields": {
                    "souvernir_pickup": true,
                    "souvernir_pickup_time": Date.now(),
                }
            }
        ], () => {
            // Success
            reloadData()
        }, () => {
            // Error
            setDrawState(5)
            alert("Cannot submit data. Please try again later.")
        })
    }

    function saveData() {
        setDrawState(6)
        updateGuest([
            {
                "id": detailRow.id,
                "fields": {
                    "Name": detailRow.name,
                    "Phone": detailRow.phone,
                    "PersonComing": parseInt(detailRow.person_coming),
                    "Table Number": detailRow.table_number,
                    "Seating Number": detailRow.seat_number,
                    "Answers": detailRow.answers,
                    "notes": detailRow.notes,
                    "no": parseInt(detailRow.number),
                    "is_rsvp": detailRow.is_rsvp_bool,
                    "is_coming": detailRow.is_coming_bool,
                    "checkin_device_id": detailRow.checkin_device_id,
                    "is_angpao": detailRow.is_angpao,
                }
            }
        ], () => {
            // Success
            reloadData()
        }, () => {
            // Error
            setDrawState(5)
            alert("Cannot submit data. Please try again later.")
        })
    }

    function saveNew() {
        setDrawState(6)
        createGuest([
            {
                "fields": {
                    "Event": event.Code,
                    "Name": detailRow.name,
                    "MaxPerson": parseInt(detailRow.person_coming),
                    "PersonComing": parseInt(detailRow.person_coming),
                    "is_rsvp": true,
                    "is_coming": true,
                    "Phone": detailRow.phone,
                    "Table Number": detailRow.table_number,
                    "Seating Number": detailRow.seat_number,
                    "Answers": detailRow.answers,
                    "notes": detailRow.notes,
                    "no": parseInt(detailRow.number),
                    "checkin_device_id": detailRow.checkin_device_id,
                    "is_angpao": detailRow.is_angpao,
                }
            }
        ], () => {
            // Success
            reloadData()
        }, () => {
            // Error
            setDrawState(7)
            alert("Cannot submit data. Please try again later.")
        })
    }

    function updateField(event) {
        setDetailRow({
            ...detailRow,
            [event.target.name]: event.target.value
        })
    }

    function updateRadio(event) {
        setDetailRow({
            ...detailRow,
            [event.target.name]: event.target.checked
        })
      }
    


    if (drawState === 0) {
        return (
            <LoadingScreen />
        )
    }
    else if (drawState === 6) {
        return (
            <ErrorScreen message="Saving data... please wait" />
        )
    }
    else if (drawState === 1) {
        return (
            <ErrorScreen message="We cannot find your invitation" />
        )
    }
    else if (drawState === 4) {
        return (
            <ErrorScreen message="Wrong Password" />
        )
    }
    else if (drawState === 2) {
        // Laporan kalau sudah lewat tanggal RSVP nya
        // Alias laporan buat acara
        return (
            <Container>
                <Row>
                    <Col>
                        <b>Control Panel for {event.Name}</b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Total Coming<br />
                        {totalComing} ({totalPersonComing} pax)
                    </Col>
                    <Col>
                        Came<br />
                        {totalCame}
                    </Col>
                    <Col>
                        No Show<br />
                        {totalNotCame} ({totalPersonComing - totalPersonCame} pax)
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Total Came<br />
                        {totalCame} ({totalPersonCame} pax)
                    </Col>
                    <Col>
                        Souvernir Pickup<br />
                        {totalSouvernir} ({totalPersonSouvernir} pax)
                    </Col>
                    <Col>
                        No Pickup<br />
                        {totalNotSouvernir} ({totalPersonComing - totalPersonSouvernir} pax)
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={6} md={3}><b>Nama|Pax</b>{' '}<Button onClick={addNewData}>Add</Button></Col>
                    <Col xs={6} md={3}><b>Phone</b></Col>
                    <Col xs={6} md={3}><b>Table/Seat</b><br/><small>Gunakan . untuk mencari precise (Ex: 1.)</small></Col>
                    <Col xs={6} md={3}><b>Number</b></Col>
                </Row>
                <Row>
                    <Col xs={6} md={3}><Form.Control placeholder='Name Filter' value={filterName} type='text' onChange={(e) => setFilterName(e.target.value.toLowerCase())} /></Col>
                    <Col xs={6} md={3}><Form.Control placeholder='Phone' value={filterPhone} type='text' onChange={(e) => setFilterPhone(e.target.value.toLowerCase())} /></Col>
                    <Col xs={6} md={3}><Form.Control placeholder='Table' value={filterTable} type='text' onChange={(e) => setFilterTable(e.target.value.toLowerCase())} /></Col>
                    <Col xs={6} md={3}><Form.Control placeholder='Number' value={filterNumber} type='text' onChange={(e) => setFilterNumber(e.target.value.toLowerCase())} /></Col>
                </Row>
                {data.map((o, i) => {
                    if (filterName != '' && o.name && !o.name.toLowerCase().includes(filterName)) {
                        return <Row></Row>
                    }
                    if (filterPhone != '' && o.phone && !o.phone.toLowerCase().includes(filterPhone)) {
                        return <Row></Row>
                    }
                    if (filterPhone != '' && !o.phone) { // kalau filter isi, yang ga ada no telp, ga usah tampil juga
                        return <Row></Row>
                    }
                    try {
                        if (filterTable.includes(".")) {
                            if (o.table_number.toString().toLowerCase() != filterTable.replace(".", "")) {
                                return <Row></Row>
                            }
                        }
                        else {
                            if (filterTable != '' && !o.table_number.toString().toLowerCase().includes(filterTable)) {
                                return <Row></Row>
                            }
                        }
                    }
                    catch(e) {
                        return <Row></Row>
                        // pass
                    }
                    if (filterNumber != '' && o.number != filterNumber) {
                        return <Row></Row>
                    }
                    return <Container>
                        <Row className='cpanel-row' onClick={() => viewDetail(o)}>
                            <Col xs={6} md={3}>
                                <div>{o.name}</div>
                                <div>Pax: {o.person_coming} {o.is_coming} {(o.is_coming_bool) ? o.is_came : ''}</div>
                            </Col>
                            <Col xs={6} md={3}>{(!o.phone) ? o.name : o.phone}</Col>
                            <Col xs={6} md={3}>Table: {o.table_number}
                            <br />Seat: {o.seat_number}</Col>
                            <Col xs={6} md={3}>Number: {o.number}</Col>
                        </Row>
                        {o.answers && 
                            <div>Answers: {o.answers}</div>
                        }
                        {o.notes && 
                            <div>Notes: {o.notes}</div>
                        }
                        <hr />
                    </Container>
                })}
            </Container>
        );
    }
    else if (drawState === 5) {
        return (
            <Container className='edit-data-container'>
                <Form>
                <h1>{detailRow.name}</h1>
                <div align='center'><img src={detailRow.qr} /></div>
                <Row>
                    <Col><h5>RSVP</h5></Col>
                    <Col><h5><Form.Check name='is_rsvp_bool' type='checkbox' checked={detailRow.is_rsvp_bool} onChange={updateRadio} /></h5></Col>
                </Row>
                <Row>
                    <Col><h5>Coming</h5></Col>
                    <Col><h5><Form.Check name='is_coming_bool' type='checkbox' checked={detailRow.is_coming_bool} onChange={updateRadio} /></h5></Col>
                </Row>
                <Row>
                    <Col><h5>Check In</h5></Col>
                    <Col>
                        {
                            (detailRow.is_came_bool) ?
                                <h5>{detailRow.is_came}</h5> :
                                <Button variant="success" onClick={() => saveCheckIn(detailRow.id)}>Check In</Button>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col><h5>Check Out</h5></Col>
                    <Col>
                        {
                            (detailRow.souvernir_pickup_bool) ?
                                <h5>{detailRow.souvernir_pickup}</h5> :
                                <Button variant="danger" onClick={() => saveCheckOut(detailRow.id)}>Check Out</Button>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col><h5>Name</h5></Col>
                    <Col><Form.Control type='text' name='name' value={detailRow.name} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Phone Number</h5></Col>
                    <Col><Form.Control type='text' name='phone' value={detailRow.phone} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Pax</h5></Col>
                    <Col><Form.Control type='text' name='person_coming' value={detailRow.person_coming} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Table Number</h5></Col>
                    <Col><Form.Control type='text' name='table_number' value={detailRow.table_number} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Number</h5></Col>
                    <Col><Form.Control type='text' name='number' value={detailRow.number} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Seating Number</h5></Col>
                    <Col><Form.Control type='text' name='seat_number' value={detailRow.seat_number} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Device ID</h5></Col>
                    <Col><Form.Control type='text' name='checkin_device_id' value={detailRow.checkin_device_id} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Angpao</h5></Col>
                    <Col><Form.Control type='text' name='is_angpao' value={detailRow.is_angpao} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Answers</h5></Col>
                    <Col><Form.Control as='textarea' name='answers' value={detailRow.answers} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Notes</h5></Col>
                    <Col><Form.Control as='textarea' name='notes' value={detailRow.notes} onChange={updateField} /></Col>
                </Row>
                <hr />
                <Button onClick={saveData}>Save Changes</Button>{' '}
                <Button onClick={cancelEdit} variant="danger">Cancel Edit</Button>
                </Form>
            </Container>
        )
    }
    else if (drawState === 7) {
        return (
            <Container className='edit-data-container'>
                <h1>Add new data</h1>
                <Row>
                    <Col><h5>Name</h5></Col>
                    <Col><Form.Control type='text' name='name' value={detailRow.name} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Pax</h5></Col>
                    <Col><Form.Control type='text' name='person_coming' value={detailRow.person_coming} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Phone Number</h5></Col>
                    <Col><Form.Control type='text' name='phone' value={detailRow.phone} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Table Number</h5></Col>
                    <Col><Form.Control type='text' name='table_number' value={detailRow.table_number} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Number</h5></Col>
                    <Col><Form.Control type='text' name='number' value={detailRow.number} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Seating Number</h5></Col>
                    <Col><Form.Control type='text' name='seat_number' value={detailRow.seat_number} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Device ID</h5></Col>
                    <Col><Form.Control type='text' name='checkin_device_id' value={detailRow.checkin_device_id} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Angpao</h5></Col>
                    <Col><Form.Control type='text' name='is_angpao' value={detailRow.is_angpao} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Answers</h5></Col>
                    <Col><Form.Control as='textarea' name='answers' value={detailRow.answers} onChange={updateField} /></Col>
                </Row>
                <Row>
                    <Col><h5>Notes</h5></Col>
                    <Col><Form.Control as='textarea' name='notes' value={detailRow.notes} onChange={updateField} /></Col>
                </Row>
                <hr />
                <Button onClick={saveNew}>Save</Button>{' '}
                <Button onClick={cancelEdit} variant="danger">Cancel Edit</Button>
            </Container>
        )
    }
}
