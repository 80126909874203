import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import amoretti_logo from './images/amoretti_logo.png';

export default function ErrorScreen (props) {
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col className="text-center">
            <p><img src={amoretti_logo} alt="Amoretti Logo" className="amoretti-logo" /></p>
            <br />
            <div>{props.message}</div>
          </Col>
        </Row>
      </Container>
    )
}