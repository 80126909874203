export const phoneNumberValidation = (phone_number) => {
  var phoneno = /^\+?[0-9]{7,15}$/;
  return phone_number.match(phoneno)
}

export const emailValidation = (email) => {
  var emailregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return email.match(emailregex)
}

