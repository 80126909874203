import React from 'react';
import { Container, Row, Col, Spinner } from "react-bootstrap";
import amoretti_logo from './images/amoretti_logo.png';

export default function LoadingScreen () {
    return (
      <Container>
        <Row className="justify-content-md-cnter">
          <Col className="text-center">
            <p><img src={amoretti_logo} alt="Amoretti Logo" className="amoretti-logo" /></p>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
            <br />
            <br />
            <br />
            <div>Loading awesomeness</div>
          </Col>
        </Row>
      </Container>
    )
}