import React, { useState , useEffect } from 'react';
import { Button, Col, Container, Row, Form } from 'react-bootstrap';
import './Home.css';
import amoretti_logo from './images/amoretti_logo.png';
import {
  useParams
} from "react-router-dom";
import LoadingScreen from './LoadingScreen';
import ErrorScreen from './ErrorScreen';
import { getEventDetail, getGuest, updateGuest } from './AirtableLib';
import { phoneNumberValidation, emailValidation } from './CommonLib';

export default function Home (props) {
  // kalau butuh state
  const [record , setRecord] = useState({
    fields: {
      Name: '',
      Phone: '',
      Email: '',
      PersonComing: '',
      is_coming: true,
    }
  });
  const [drawState , setDrawState] = useState(0);
  const [headerImage , setHeaderImage] = useState("");
  const [rsvpUntil , setRsvpUntil] = useState("");
  const [extraQuestions , setExtraQuestions] = useState("");
  const [customAnswers , setCustomAnswers] = useState({});
  const [alreadyClicked , setAlreadyClicked] = useState(false);

  let { event_code , guest_id } = useParams();

  // Pengganti di componentDidMount untuk functional component
  useEffect(() => {
    getEventDetail(event_code, (record3) => {
      setHeaderImage(record3.fields.Header[0].url || "")
      setRsvpUntil(record3.fields.LastRSVP || "")
      if (record3.fields.Questions) {
        var question_forms = []

        record3.fields.Questions.split('\n').forEach(lines => {
          console.log(lines)
          var [type, id, title, options] = lines.split("|")
          if (type === 'text') {
            customAnswers[title] = ""
            setCustomAnswers(customAnswers)
            question_forms.push(
              <Form.Group controlId="customForm{id}">
                <Form.Label>{title}</Form.Label>
                <Form.Control type="text" name={title} onChange={updateCustom} />
              </Form.Group>
            )
          }
          else if (type === 'select') {
            var options_obj = []
            customAnswers[title] = options.split("#")[0]
            setCustomAnswers(customAnswers)
            options.split("#").forEach(op => {
              options_obj.push(
                <option key={op}>{op}</option>
              )
            });
            question_forms.push(
              <Form.Group controlId={id} key={id}>
                <Form.Label key={id}>{title}</Form.Label>
                <Form.Control as='select' name={title} onChange={updateCustomSelect}>
                  {options_obj}
                </Form.Control>
              </Form.Group>
            )
          }
        });

        // Ada pertanyaan tambahan, build di sini
        setExtraQuestions(question_forms)
      }

      // Masih bisa RSVP
      // Abis itu langsung ambil guest nya
      getGuest(guest_id , (record2) => {
        // Kelar ambil guest
        record2.fields.PersonComing = record2.fields.PersonComing || record2.fields.MaxPerson
        record2.fields.is_coming = record2.fields.is_coming || false
        record2.fields.Phone = record2.fields.Phone || ""
        record2.fields.Name = record2.fields.Name || ""
        record2.fields.Email = record2.fields.Email || ""
        setRecord(record2)

        // Check apakah dia masih bisa RSVP apa engga
        var current_date = new Date()
        var date_string = current_date.toISOString().split("T")[0]
        if (record3.fields.LastRSVP < date_string) {
          // Sudah tidak bisa lagi RSVP
          setDrawState(4)
        }
        else {
          setDrawState(2)
        }


      }, () => {
        // Error ambil guest
        setDrawState(1)
      })
    }, () => {
      // Error ambil Event
      setDrawState(1)
    })
  }, []) // Set empty dependency biar ga load hanya sekali, biar setRecord ga trigger ulang2

  function updateField(event) {
    setRecord({
      ...record,
      fields: {
        ...record.fields,
        [event.target.name] : event.target.value
      }
    })
  }

  function updateCustom(event) {
    customAnswers[event.target.name] = event.target.value
    setCustomAnswers(customAnswers)
  }

  function updateCustomSelect(event) {
    customAnswers[event.target.name] = event.target.value
    setCustomAnswers(customAnswers)
    console.log(customAnswers)
  }

  function updateRadio(event) {
    setAlreadyClicked(true);
    var record2 = {...record}
    if (event.target.id === 'coming_ok') {
      record2.fields.is_coming = true
      setRecord(record2)
    }
    else {
      record2.fields.is_coming = false
      setRecord(record2)
    }
    console.log(record)
  }

  function send_email() {
    let url = `https://weddingbook.rav.ninja/send_confirmation_email?event_code=${event_code}&guest_id=${guest_id}`
    fetch(url)
      .then(
        (result) => {
          // Berhasil, probably do nothing
        },
        (error) => {
          alert("Cannot send email confirmation. Don't worry, we will try to send the email later. You can safely leave this page")
        }
      )
  }

  function saveData(event) {
    event.preventDefault()

    // Dibuat jadi optional
    // if (record.fields.Email === "") {
    //   alert("Please enter email address")
    //   return
    // }

    if (!alreadyClicked) {
      alert("Please click either coming or not coming");
      return;
    }

    if (record.fields.Phone === "") {
      alert("Please enter mobile phone number")
      return
    }

    if (record.fields.Email !== "" && !emailValidation(record.fields.Email)) {
      alert("Invalid Email")
      return
    }

    if (!phoneNumberValidation(record.fields.Phone)) {
      alert("Invalid Phone Number")
      return
    }

    if (parseInt(record.fields.PersonComing) > record.fields.MaxPerson) {
      alert(`Maximum number of guest is ${record.fields.MaxPerson}`)
      return
    }

    if (record.fields.PersonComing === "") {
      alert("Please enter person coming")
      return
    }

    if (parseInt(record.fields.PersonComing) < 0) {
      alert(`Attending guest minimum of 1`)
      return
    }

    var updated_fields = {
      "Phone": record.fields.Phone,
      "Email": record.fields.Email,
      "PersonComing": parseInt(record.fields.PersonComing),
      "Name": record.fields.Name,
      "is_coming": record.fields.is_coming,
      "is_rsvp": true,
      "rsvp_time": Date.now(),
    }
    if (Object.keys(customAnswers).length > 0) {
      console.log("ini ada field nya")
      // Backward compatible, supaya bisa support airtable yang ga punya field Answers
      var answers = "";
      for (const [key,value] of Object.entries(customAnswers)) {
        answers += `${key}: ${value}\n`
      }
      updated_fields['Answers'] = answers
    }
    
    let datas = [
      {
        "id": record.id,
        "fields": updated_fields,
      }
    ]
    updateGuest(datas, () => {
      // Success, engga perlu ngapa2in
      // alert("Thanks for the RSVP. You can change detail anytime by visiting this page again.")
      setDrawState(5)
      // Try to send email
      send_email()
    }, () => {
      // Error
      alert("Cannot submit data. Please try again later.")
    })
  }

  if (drawState === 0) {
    return (
      <LoadingScreen />
    )
  }
  else if (drawState === 1) {
    return (
      <ErrorScreen message="We cannot find your invitation" />
    )
  }
  else if (drawState === 2) {
    return (
      <Container>
        <Row>
          <Col xl={6}>
            <img src={headerImage} alt="Invitation images" className="img-fluid" />
          </Col>
          <Col xl={6}>
            <br/>
            <h1 className="handwritten-title">Hi, {record.fields.Name}</h1>
            <h1>Please RSVP</h1>
            <p>Last RSVP Time: {rsvpUntil}</p>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="Name" placeholder="name" value={record.fields.Name} onChange={updateField} />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="Email" placeholder="Enter email" value={record.fields.Email} onChange={updateField} />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="tel" name="Phone" placeholder="Enter phone" value={record.fields.Phone} onChange={updateField} />
              </Form.Group>
    
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Number of guest attending</Form.Label>
                <Form.Control type="number" name="PersonComing" value={record.fields.PersonComing} onChange={updateField} />
                <Form.Text className="text-muted">
                  Valid for {record.fields.MaxPerson} persons.
                </Form.Text>
              </Form.Group>

              {extraQuestions}

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check name="coming" type="radio" id="coming_ok" label="Accepts with pleasure / Saya bisa hadir" checked={record.fields.is_coming && alreadyClicked} onChange={updateRadio} />
                <Form.Check name="coming" type="radio" id="coming_not_ok" label="Declines with regrets / Saya tidak bisa hadir" checked={!record.fields.is_coming && alreadyClicked} onChange={updateRadio} />
              </Form.Group>
              <Button variant="primary" type="submit" onClick={saveData}>
              Submit
              </Button>
              <p className="text-center"><img src={amoretti_logo} alt="Amoretti Logo" className="amoretti-logo" /></p>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
  else if (drawState === 4) {
    return (
      <Container>
        <Row>
          <Col xl={6}>
            <img src={headerImage} alt="Invitation images" className="img-fluid" />
          </Col>
          <Col xl={6}>
            <br/>
            <h1 className="handwritten-title">Hi, {record.fields.Name}</h1>
            <p>Sorry but you cannot change your RSVP data anymore.</p>
            <p>Last RSVP Time: {rsvpUntil}</p>
            <p>Below is the data you have been submitted</p>
            <p>Email: {record.fields.Email}</p>
            <p>Phone: {record.fields.Phone}</p>
            <p>Number of guest attending: {record.fields.PersonComing}</p>
            <p>Is Coming: {record.fields.is_coming? "Yes" : "No"}</p>
            <p className="text-center"><img src={amoretti_logo} alt="Amoretti Logo" className="amoretti-logo" /></p>
          </Col>
        </Row>
      </Container>
    );
  }
  else if (drawState === 5) {
    return (
      <Container>
        <Row>
          <Col xl={6}>
            <img src={headerImage} alt="Invitation images" className="img-fluid" />
          </Col>
          <Col xl={6}>
            <br/>
            <h1 className="handwritten-title">Hi, {record.fields.Name}</h1>
            <p>Thank you for your confirmation.</p>
            {
              record.fields.is_coming ? 
              <p>Looking forward to see you.</p> :
              <p>Oh, that’s too bad. We’ll miss you.</p>
            }
            <p>You can change detail anytime by visiting this page again.</p>
            <p className="text-center"><img src={amoretti_logo} alt="Amoretti Logo" className="amoretti-logo" /></p>
          </Col>
        </Row>
      </Container>
    );
  }
}
