import React, { useState , useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Home.css';
import {
  useParams
} from "react-router-dom";
import { getEventDetail, getAllGuest } from './AirtableLib';
import LoadingScreen from './LoadingScreen';
import ErrorScreen from './ErrorScreen';
import MyPie from './MyPie';
import BootstrapTable from 'react-bootstrap-table-next';

export default function Report (props) {
  // kalau butuh state
  const [drawState , setDrawState] = useState(0);
  const [event , setEvent] = useState({});
  const [totalInvitation , setTotalInvitation] = useState(0);
  const [totalRsvp , setTotalRsvp] = useState(0);
  const [totalNotRsvp , setTotalNotRsvp] = useState(0);
  const [totalComing , setTotalComing] = useState(0);
  const [totalNotComing , setTotalNotComing] = useState(0);
  const [totalCame , setTotalCame] = useState(0);
  const [totalNotCame , setTotalNotCame] = useState(0);
  const [totalSouvernir , setTotalSouvernir] = useState(0);
  const [totalNotSouvernir , setTotalNotSouvernir] = useState(0);
  const [totalPersonComing , setTotalPersonComing] = useState(0);
  const [totalPersonCame , setTotalPersonCame] = useState(0);
  const [totalPersonSouvernir , setTotalPersonSouvernir] = useState(0);
  const [graphData , setGraphData] = useState([]);
  const [data , setData] = useState([]);
  const columns = [
    {dataField: 'name' , text: 'Name', sort: true , headerStyle: {width: '50%'}},
    {dataField: 'is_rsvp' , text: 'RSVP', sort: true},
    {dataField: 'is_coming' , text: 'Coming', sort: true},
    {dataField: 'PersonComing' , text: 'Person', sort: true},
    {dataField: 'answers' , text: 'Answers', sort: true},
  ]
  const columnsAfter = [
    {dataField: 'name' , text: 'Name', sort: true , headerStyle: {width: '50%'}},
    {dataField: 'is_rsvp' , text: 'RSVP', sort: true},
    {dataField: 'is_coming' , text: 'Coming', sort: true},
    {dataField: 'is_came' , text: 'Came', sort: true},
    {dataField: 'souvernir_pickup' , text: 'Souvernir', sort: true},
    {dataField: 'checkin_device_id' , text: 'Device', sort: true},
    {dataField: 'is_angpao' , text: 'Angpao', sort: true},
    {dataField: 'no' , text: '#', sort: true},
  ]

  // Variable yang bukan state
  // const lastId = useRef("")

  let { event_code, base_id } = useParams();

  // Pengganti di componentDidMount untuk functional component
  useEffect(() => {
    getEventDetail(event_code, (record) => {
      if (record.fields.base !== base_id) {
        console.log(record)
        console.log(record.fields.base , base_id)
        // Salah password
        setDrawState(4)
        return
      }
      setEvent(record.fields)
      // Success
      getAllGuest(event_code , (record2) => {
        var total_invitation = 0
        var is_rsvp_total = 0
        var is_coming_total = 0
        var is_came_total = 0
        var souvernir_pickup_total = 0
        var total_person_coming = 0
        var total_person_came = 0
        var total_person_souvernir = 0
        var total_person = 0
        var _data = []
        record2.forEach(guest => {
          total_invitation += 1
          if (guest.fields.is_rsvp) is_rsvp_total += 1
          if (guest.fields.is_coming) {
            is_coming_total += 1
            total_person_coming += guest.fields.PersonComing || 0
          }
          if (guest.fields.is_came) {
            is_came_total += 1
            total_person_came += guest.fields.PersonComing || 0
          }
          if (guest.fields.souvernir_pickup) {
            souvernir_pickup_total += 1
            total_person_souvernir += guest.fields.PersonComing || 0
          }
          _data.push({
            id: guest.id , 
            name: guest.fields.Name,
            is_rsvp: guest.fields.is_rsvp?"✅":"❌",
            is_coming: guest.fields.is_coming?"✅":"❌",
            is_came: guest.fields.is_came?"✅":"❌",
            souvernir_pickup: guest.fields.souvernir_pickup?"✅":"❌",
            PersonComing: guest.fields.PersonComing,
            checkin_device_id: guest.fields.checkin_device_id,
            is_angpao: guest.fields.is_angpao,
            no: guest.fields.no,
            answers: guest.fields.Answers,
            notes: guest.fields.notes,
          })
        });
        setData(_data)
        setTotalInvitation(total_invitation)
        setTotalRsvp(is_rsvp_total)
        setTotalNotRsvp(total_invitation-is_rsvp_total)
        setTotalComing(is_coming_total)
        setTotalNotComing(is_rsvp_total-is_coming_total)
        setTotalCame(is_came_total)
        setTotalNotCame(is_coming_total-is_came_total)
        setTotalSouvernir(souvernir_pickup_total)
        setTotalNotSouvernir(is_came_total-souvernir_pickup_total)
        setTotalPersonComing(total_person_coming)
        setTotalPersonCame(total_person_came)
        setTotalPersonSouvernir(total_person_souvernir)

        setGraphData([
          { name: 'Coming', value: is_coming_total , fill: '#799351'}, 
          { name: 'Not Yet RSVP', value: total_invitation-is_rsvp_total , fill: '#ebdc87'},
          { name: 'Not Coming', value: is_rsvp_total-is_coming_total, fill: '#d54062'},
        ])

        // Check apa tanggal terakhir rsvp sudah lewat apa belum, biar tau harus tampilkan report yang mana?
        var current_date = new Date()
        var date_string = current_date.toISOString().split("T")[0]
        console.log(record.fields.Tanggal, date_string)
        if (record.fields.Tanggal <= date_string) {
          // Sudah tidak bisa lagi RSVP
          // Keluarkan laporan kedatangan
          setDrawState(3)
        }
        else {
          // Masih bisa RSVP
          // Tampilkan laporan RSVP nya
          setDrawState(2)
        }

      } , (err) => {
        // Gagal
        setDrawState(1)
      })
    }, () => {
      // Gagal
      setDrawState(1)
    })
  }, []) // Set empty dependency biar ga load hanya sekali, biar setRecord ga trigger ulang2

  if (drawState === 0) {
    return (
      <LoadingScreen />
    )
  }
  else if (drawState === 1) {
    return (
      <ErrorScreen message="We cannot find your invitation" />
    )
  }
  else if (drawState === 4) {
    return (
      <ErrorScreen message="Wrong Password" />
    )
  }
  else if (drawState === 2) {
    // Laporan kalau belum kelar tanggal RSVP nya
    return (
      <Container>
        <Row>
          <Col>
            <h2>RSVP Report for {event.Name}</h2>
          </Col>
        </Row>
        <MyPie data={graphData}></MyPie>
        <Row>
          <Col>
            <h5>Total Invitation</h5>
            <h5>{totalInvitation}</h5>
          </Col>
          <Col>
            <h5>RSVP</h5>
            <h5>{totalRsvp}</h5>
          </Col>
          <Col>
            <h5>Not Yet RSVP</h5>
            <h5>{totalNotRsvp}</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Total RSVP</h5>
            <h5>{totalRsvp}</h5>
          </Col>
          <Col>
            <h5>Coming</h5>
            <h5>{totalComing} ({totalPersonComing} person)</h5>
          </Col>
          <Col>
            <h5>Not Coming</h5>
            <h5>{totalNotComing}</h5>
          </Col>
        </Row>
        <hr />
        <h5>Guest Report</h5>
        <BootstrapTable keyField='id' data={data} columns={columns} bootstrap4 />
      </Container>
    );
  }
  else if (drawState === 3) {
    // Laporan kalau sudah lewat tanggal RSVP nya
    // Alias laporan buat acara
    return (
      <Container>
        <Row>
          <Col>
            <h2>Attendance Report for {event.Name}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Total Coming</h5>
            <h5>{totalComing} ({totalPersonComing} pax)</h5>
          </Col>
          <Col>
            <h5>Came</h5>
            <h5>{totalCame}</h5>
          </Col>
          <Col>
            <h5>No Show</h5>
            <h5>{totalNotCame} ({totalPersonComing-totalPersonCame} pax)</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Total Came</h5>
            <h5>{totalCame} ({totalPersonCame} pax)</h5>
          </Col>
          <Col>
            <h5>Souvernir Pickup</h5>
            <h5>{totalSouvernir} ({totalPersonSouvernir} pax)</h5>
          </Col>
          <Col>
            <h5>No Pickup</h5>
            <h5>{totalNotSouvernir} ({totalPersonComing-totalPersonSouvernir} pax)</h5>
          </Col>
        </Row>
        <hr />
        <h5>Guest Report</h5>
        <BootstrapTable keyField='id' data={data} columns={columnsAfter} bootstrap4 />
      </Container>
    );
  }
}
