import React, { useState , useEffect } from 'react';
import { Button, Col, Container, Row, Form, ButtonGroup } from 'react-bootstrap';
import {
  useHistory,
  useParams
} from "react-router-dom";
import LoadingScreen from './LoadingScreen';
import ErrorScreen from './ErrorScreen';
import { getActiveEvent } from './AirtableLib';


export default function EventSelect (props) {
  // kalau butuh state
  const [data , setData] = useState([]);
  const [drawState, setDrawState] = useState(0);

//   let { event_code , guest_id } = useParams();

  // Pengganti di componentDidMount untuk functional component
  useEffect(() => {
    getActiveEvent((record2) => {
      setData(record2);
      setDrawState(2)
    }, () => {
      // Error ambil Event
      setDrawState(1)
    })
  }, []) // Set empty dependency biar ga load hanya sekali, biar setRecord ga trigger ulang2

  const history = useHistory()
  const routeChange = (path) =>{ 
    history.push(path)
  }

  if (drawState === 0) {
    return (
      <LoadingScreen />
    )
  }
  else if (drawState === 1) {
    return (
      <ErrorScreen message="Cannot find active events" />
    )
  }
  else if (drawState === 2) {
    return (
      <Container>
        <h1>Amoretti Active Events</h1>
        {data.map((o , i) => <Row>
          <Col>{o.fields.Name}</Col>
          <Col>
            <ButtonGroup aria-label="Basic example">
              <Button size='sm' variant="outline-primary" onClick={() => routeChange(`/check_in/${o.fields.Code}`)}>CheckIn</Button>
              <Button size='sm' variant="outline-primary" onClick={() => routeChange(`/check_out/${o.fields.Code}`)}>CheckOut</Button>
              <Button size='sm' variant="outline-primary" onClick={() => routeChange(`/cpanel/${o.fields.Code}/${o.fields.base}`)}>Cpanel</Button>
              <Button size='sm' variant="outline-primary" onClick={() => routeChange(`/report/${o.fields.Code}/${o.fields.base}`)}>Report</Button>
              <Button size='sm' variant="outline-primary" onClick={() => routeChange(`/angpao/${o.fields.Code}/${o.fields.base}`)}>Angpao</Button>
              <Button size='sm' variant="outline-primary" onClick={() => routeChange(`/printout/${o.fields.Code}/${o.fields.base}`)}>Print</Button>
              {/* <Button size='sm' variant="outline-primary" onClick={() => routeChange(`/checklist/${o.fields.Code}/${o.fields.base}`)}>Check</Button> */}
            </ButtonGroup>
          </Col>
        </Row>)}
        <hr />
        <h6>Version 221213 - Fred</h6>
      </Container>
    );
  }
}
