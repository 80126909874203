import React, { useState , useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Home.css';
import {
  useParams
} from "react-router-dom";
import QrReader from 'react-qr-reader'
import { getEventDetail, updateGuest, getGuest } from './AirtableLib';
import LoadingScreen from './LoadingScreen';
import ErrorScreen from './ErrorScreen';

export default function CheckOut (props) {
  // kalau butuh state
  const [drawState , setDrawState] = useState(0);
  const [guestName , setGuestName] = useState("");
  const [eventName , seteventName] = useState("");
  const [status , setStatus] = useState("Waiting for QR")
  // Variable yang bukan state
  const lastId = useRef("")

  let { event_code } = useParams();

  // Pengganti di componentDidMount untuk functional component
  useEffect(() => {
    getEventDetail(event_code, (record) => {
      // Success
      seteventName(record.fields.Name)
      setDrawState(2)
    }, () => {
      // Gagal
      setDrawState(1)
    })
  }, []) // Set empty dependency biar ga load hanya sekali, biar setRecord ga trigger ulang2

  function saveCheckOut(id) {
    updateGuest([
      {
        "id": id,
        "fields": {
          "souvernir_pickup": true,
          "souvernir_pickup_time": Date.now(),
        }
      }
    ], () => {
      // Success
      setStatus("OK")
    }, () => {
      // Error
      setStatus("Cannot submit data. Please try again later.")
    })
  }

  function qr_error(err) {
      console.error(err)
  }

  function qr_success(data) {
      if (data) {
        doSearch(data)
      }
  }

  function doSearch(guest_id) {
    if (guest_id === lastId.current) {
      console.log("Duplicate")
      return
    }
    else {
      lastId.current = guest_id
    }
    setGuestName("")
    setStatus("Searching guest data")
    getGuest(guest_id , (record) => {
      let pickup_status = record.fields.souvernir_pickup || false
      console.log('Retrieved', record.id);
      if (pickup_status === true) {
        setStatus("SOUVERNIR TAKEN")
        setGuestName(record.fields.Name || "")
      }
      else {
        setStatus("Saving CheckOut Data..")
        setGuestName(record.fields.Name || "")
        saveCheckOut(record.id)
      }
    }, () => {
      // Error
      setStatus("Cannot find quest with this QR")
      setGuestName("")
    })
  }

  if (drawState === 0) {
    return (
      <LoadingScreen />
    )
  }
  else if (drawState === 1) {
    return (
      <ErrorScreen message="We cannot find your invitation" />
    )
  }
  else if (drawState === 2) {
    return (
      <Container>
        <Row>
          <Col>
            <br/>
            <h1>{eventName} Checkout</h1>
            <p>Scan Souvernir Barcode to Claim</p>
            <QrReader
                delay={100}
                onError={qr_error}
                onScan={qr_success}
                facingMode='environment'
            />
            <h3>Guest: {guestName}</h3>
            <h3>Status: {status}</h3>
          </Col>
        </Row>
      </Container>
    );
  }
}
