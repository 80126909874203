import React, { PureComponent } from 'react';
import {
  PieChart, Pie, Tooltip, ResponsiveContainer
} from 'recharts';

export default class MyPie extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width='100%' height={200}>
        <PieChart>
          <Tooltip />
          <Pie data={this.props.data} dataKey="value" outerRadius={60} fill="#8884d8" label />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
