import React, { useState , useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './CheckIn.css';
import amoretti_logo from './images/amoretti_logo.png';
import {
  useParams
} from "react-router-dom";
import QrReader from 'react-qr-reader'
import { getEventDetail, updateGuest, getGuest } from './AirtableLib';
import LoadingScreen from './LoadingScreen';
import ErrorScreen from './ErrorScreen';

export default function CheckIn () {
  // kalau butuh state
  const [drawState , setDrawState] = useState(0);
  const [headerImage , setHeaderImage] = useState("");
  const [data , setData] = useState({});
  const [warningMessage , setWarning] = useState("");
  // Variable yang bukan state
  const lastId = useRef("")

  let { event_code } = useParams();

  // Pengganti di componentDidMount untuk functional component
  useEffect(() => {
    getEventDetail(event_code, (record) => {
      // Success
      setHeaderImage(record.fields.Header[0].url || "")
      setDrawState(2)
    }, () => {
      // Gagal
      setDrawState(1)
    })
  }, []) // Set empty dependency biar ga load hanya sekali, biar setRecord ga trigger ulang2

  function qr_error(err) {
    console.error(err)
  }

  function qr_success(data) {
      if (data) {
        doSearch(data)
      }
  }

  function saveCheckIn(id) {
    updateGuest([
      {
        "id": id,
        "fields": {
          "is_came": true,
          "came_time": Date.now(),
        }
      }
    ], () => {
      // Success, engga perlu ngapa2in
    }, () => {
      // Error
      setWarning("Cannot submit data. Please try again later.")
    })
  }

  function doSearch(guest_id) {
    if (guest_id === lastId.current) {
      console.log("Duplicate")
      return
    }
    else {
      lastId.current = guest_id
    }

    getGuest(guest_id , (record) => {
      if (!record.fields.is_rsvp || !record.fields.is_coming) {
        setData(record.fields)
        setWarning("Please Wait. We can't find your RSVP")
      }
      else {
        setData(record.fields)
        saveCheckIn(record.id)
        setWarning("")
      }
    }, () => {
      // Error
      setData({})
      setWarning("Sorry We Cannot find your reservation")
    })
  }

  if (drawState === 0) {
    return (
      <LoadingScreen />
    )
  }
  else if (drawState === 1) {
    return (
      <ErrorScreen message="We cannot find your invitation" />
    )
  }
  else if (drawState === 2) {
    return (
      <Container>
        <Row className='checkin-container'>
          <Col sm={5} className="white-bg">
            <img src={headerImage} alt="Invitation images" className="img-fluid" />
          </Col>
          <Col sm={5} className="white-bg">
            <h2>Scan QR To Check In</h2>
            <QrReader
              delay={100}
              onError={qr_error}
              onScan={qr_success}
              style={{ width: '300px' }}
              facingMode='user'
            />
            <br/>
            <h2 className="ch">Hi, {data.Name}</h2>
            <p>Thanks for coming</p>
            <h2>Seating Information</h2>
            <h5>Table Number : {data['Table Number']}</h5>
            {data['Seating Number']
            ? <h5>Seating Number : {data['Seating Number']}</h5>
            : <b></b>
            }
            <h5>Number of Person : {data['PersonComing']}</h5>
            <h5>Guest Number : {data['no']}</h5>
            <pre><h5>{data['Answers']}</h5></pre>
            <h2>{warningMessage}</h2>
          </Col>
          <Col sm={2}>
            <p className="text-center"><img src={amoretti_logo} alt="Amoretti Logo" className="amoretti-logo-checkin" /></p>
            <h6 className="text-center">RSVP System</h6>
          </Col>
        </Row>
      </Container>
    );
  }
}
