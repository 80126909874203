import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import './Home.css';
import {
  useParams
} from "react-router-dom";
import { getEventDetail, getAllGuest } from './AirtableLib';
import LoadingScreen from './LoadingScreen';
import ErrorScreen from './ErrorScreen';
import MyPie from './MyPie';
import BootstrapTable from 'react-bootstrap-table-next';

export default function Angpao(props) {
  // kalau butuh state
  const [drawState, setDrawState] = useState(0);
  const [event, setEvent] = useState({});
  const [totalGift, setTotalGift] = useState(0);
  const [totalGiftFiltered, setTotalGiftFilted] = useState(0);
  const [totalCame, setTotalCame] = useState(0);
  const [totalPersonCame, setTotalPersonCame] = useState(0);
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const columns = [
    { dataField: 'name', text: 'Name', sort: true, headerStyle: { width: '50%' } },
    { dataField: 'is_rsvp', text: 'RSVP', sort: true },
    { dataField: 'is_coming', text: 'Coming', sort: true },
    { dataField: 'PersonComing', text: 'Person', sort: true },
    { dataField: 'answers', text: 'Answers', sort: true },
  ]
  const columnsAfter = [
    { dataField: 'name', text: 'Name', sort: true, headerStyle: { width: '50%' } },
    { dataField: 'is_rsvp', text: 'RSVP', sort: true },
    { dataField: 'is_coming', text: 'Coming', sort: true },
    { dataField: 'is_came', text: 'Came', sort: true },
    { dataField: 'souvernir_pickup', text: 'Souvernir', sort: true },
    { dataField: 'checkin_device_id', text: 'Device', sort: true },
    { dataField: 'is_angpao', text: 'Angpao', sort: true },
    { dataField: 'no', text: '#', sort: true },
  ]

  // Variable yang bukan state
  // const lastId = useRef("")

  let { event_code, base_id } = useParams();

  function calculateGraph(data) {
    var count = {}
    var _graph = []
    data.forEach(d => {
      if (d.checkin_device_id && !count.hasOwnProperty(d.checkin_device_id)) {
        count[d.checkin_device_id] = 0
      }
      if (d.checkin_device_id) count[d.checkin_device_id] += 1
    });
    for (const key in count) {
      if (Object.hasOwnProperty.call(count, key)) {
        const element = count[key];
        var color = '#799351';
        if (key.toLowerCase() === 'a') {
          color = '#ebdc87'
        }
        else if (key.toLowerCase() === 'b') {
          color = '#d54062'
        }
        else if (key.toLowerCase() === 'c') {
          color = '#7fffd4'
        }
        else if (key.toLowerCase() === 'd') {
          color = '#ff4040'
        }
        else if (key.toLowerCase() === 'e') {
          color = '#7fff00'
        }
        else if (key.toLowerCase() === 'f') {
          color = '#ff1493'
        }
        _graph.push({
          name: key,
          value: count[key],
          fill: color
        })
      }
    }
    setGraphData(_graph)
  }

  function filterData(filterString) {
    var _fulldata = fullData
    var _data = []
    var filter_count = 0
    _fulldata.forEach(d => {
      if (filterString === '') {
        _data.push(d)
        filter_count += 1
      }
      else {
        if (d.is_angpao && filterString == d.is_angpao.toLowerCase()) {
          _data.push(d)
          filter_count += 1
        }
      }
    })

    setData(_data)
    setTotalGiftFilted(filter_count)
    calculateGraph(_data)
  }

  // Pengganti di componentDidMount untuk functional component
  useEffect(() => {
    getEventDetail(event_code, (record) => {
      if (record.fields.base !== base_id) {
        console.log(record)
        console.log(record.fields.base, base_id)
        // Salah password
        setDrawState(4)
        return
      }
      setEvent(record.fields)
      // Success
      getAllGuest(event_code, (record2) => {
        var total_invitation = 0
        var is_came_total = 0
        var total_person_came = 0
        var total_gift = 0
        var _data = []
        record2.forEach(guest => {
          total_invitation += 1
          if (guest.fields.is_came) {
            is_came_total += 1
            total_person_came += guest.fields.PersonComing || 0
          }
          if (guest.fields.is_angpao) {
            total_gift += 1
          }
          _data.push({
            id: guest.id,
            name: guest.fields.Name,
            is_rsvp: guest.fields.is_rsvp ? "✅" : "❌",
            is_coming: guest.fields.is_coming ? "✅" : "❌",
            is_came: guest.fields.is_came ? "✅" : "❌",
            souvernir_pickup: guest.fields.souvernir_pickup ? "✅" : "❌",
            PersonComing: guest.fields.PersonComing,
            checkin_device_id: guest.fields.checkin_device_id,
            is_angpao: guest.fields.is_angpao,
            no: guest.fields.no,
            answers: guest.fields.Answers,
            notes: guest.fields.notes,
          })
        });
        setData(_data)
        setFullData(_data)
        calculateGraph(_data)
        setTotalCame(is_came_total)
        setTotalPersonCame(total_person_came)
        setTotalGift(total_gift)
        setTotalGiftFilted(total_gift)

        setDrawState(3)

      }, (err) => {
        // Gagal
        setDrawState(1)
      })
    }, () => {
      // Gagal
      setDrawState(1)
    })
  }, []) // Set empty dependency biar ga load hanya sekali, biar setRecord ga trigger ulang2

  if (drawState === 0) {
    return (
      <LoadingScreen />
    )
  }
  else if (drawState === 1) {
    return (
      <ErrorScreen message="We cannot find your invitation" />
    )
  }
  else if (drawState === 4) {
    return (
      <ErrorScreen message="Wrong Password" />
    )
  }
  else if (drawState === 3) {
    // Laporan kalau sudah lewat tanggal RSVP nya
    // Alias laporan buat acara
    return (
      <Container>
        <Row>
          <Col>
            <h2>Gift Report for {event.Name}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Total Came</h5>
            <h5>{totalCame} ({totalPersonCame} pax)</h5>
          </Col>
          <Col>
            <h5>Total Gift</h5>
            <h5>{totalGift}</h5>
          </Col>
          <Col>
            <h5>Filtered</h5>
            <h5>{totalGiftFiltered}</h5>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Button onClick={() => filterData('')}>All</Button>
          </Col>
          <Col>
            <Button onClick={() => filterData('paper')}>Paper</Button>
          </Col>
          <Col>
            <Button onClick={() => filterData('qr')}>QR</Button>
          </Col>
          <Col>
            <Button onClick={() => filterData('gift')}>Gift</Button>
          </Col>
          <Col>
            <Button onClick={() => filterData('none')}>None</Button>
          </Col>
        </Row>
        <Row>
          <br />
        </Row>
        <MyPie data={graphData}></MyPie>
        <BootstrapTable keyField='id' data={data} columns={columnsAfter} bootstrap4 />
      </Container>
    );
  }
}
