import Airtable from 'airtable';

const API_KEY = 'keykJ1tIyGo1KEV3j'
const MAIN_BASE = 'appXe9x4KKox9k6QA'
var detailBase = null

export const getEventDetail = (event_code , success , error_callback) => {
    var mainBase = new Airtable({apiKey: API_KEY}).base(MAIN_BASE);
    mainBase('Event').select({
        filterByFormula: `{Code} = '${event_code}'`
    }).eachPage(function page(record2 , fetchNextPage) {
        if (record2.length > 0) {
            record2.forEach(function (record3) {
                // Simpen detail base nya di sini
                let detail_base_key = record3.fields.base
                detailBase = new Airtable({apiKey: API_KEY}).base(detail_base_key);
                
                // Return callback
                success(record3)
            })
        }
        else {
            console.log("Cannot find event")
            // Error Callback
            error_callback()
        }
    } , function done(err) {
        if (err) {
            console.log("Error find event" , err)
            error_callback(err)
        }
    })
}

export const updateGuest = (data , success, error_callback) => {
    detailBase('Guest').update(data, function(err, records) {
        if (err) {
          console.error(err);
          error_callback()
        }
        else {
            success(records)
        }
    });
}

export const createGuest = (data , success, error_callback) => {
    detailBase('Guest').create(data, function(err, records) {
        if (err) {
          console.error(err);
          error_callback()
        }
        else {
            success(records)
        }
    });
}

export const getGuest = (guest_id , success , error_callback) => {
    detailBase('Guest').find(guest_id, function(err, record3) {
        if (err) { 
          if (err.error === 'NOT_FOUND') {
            error_callback()
          }
          return;
        }
        else {
            success(record3)
        }
    })
}

export const getAllGuest = (event_code , success , error_callback) => {
    var datas = []
    detailBase('Guest').select({
        filterByFormula: `{Event} = '${event_code}'`,
        sort: [{field: 'Name' , direction: 'asc'}],
    }).eachPage(function page(record2 , fetchNextPage) {
        if (record2.length > 0) {
            datas.push(...record2)
            fetchNextPage()
        }
        else {
            console.log("Cannot find guest list")
            // Error Callback
            error_callback()
        }
    } , function done(err) {
        if (err) {
            console.log("Error find guest list" , err)
            error_callback(err)
        }
        else {
            success(datas)
        }
    })
}

export const getActiveEvent = (success , error_callback) => {
    var mainBase = new Airtable({apiKey: API_KEY}).base(MAIN_BASE);
    var datas = []
    mainBase('Event').select({
        filterByFormula: `{Active} = 1`,
        sort: [{field: 'Tanggal' , direction: 'asc'}],
    }).eachPage(function page(record2 , fetchNextPage) {
        if (record2.length > 0) {
            datas.push(...record2)
            fetchNextPage()
        }
        else {
            console.log("Cannot find guest list")
            // Error Callback
            error_callback()
        }
    } , function done(err) {
        if (err) {
            console.log("Error find guest list" , err)
            error_callback(err)
        }
        else {
            success(datas)
        }
    })
}
